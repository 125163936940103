/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  // Alert,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
// import ContentCard from '../../components/cards/ContentCard';
import LoadingSpinner from '../../components/LoadingSpinner';
import { URLS } from '../../constants';
import { checkErrorMsg } from '../../utils/notifications';
import { sgAxios } from '../../utils/sgAxios';

const TryoutNew = () => {
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { code } = useParams();

  const checkPayment = async (url) => {
    try {
      const resp = await sgAxios.get(url);
      const respData = get(resp, 'data.data');
      console.log(respData);
    } catch (error) {
      checkErrorMsg(error);
    }
  };

  const openGopay = async (url) => {
    setIsLoading(true);
    try {
      const win = window.open(url, '_blank');
      win.focus();
    } catch (error) {
      checkErrorMsg(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!payment) {
      const fetchPaymentGopay = async () => {
        setIsLoading(true);
        try {
          const resp = await sgAxios.get(`${URLS.PAYMENT.GOPAY}/${code}`);
          const respData = get(resp, 'data.data');
          setPayment(respData);
          setTimeout(() => {
            checkPayment(
              'https://api.sandbox.veritrans.co.id/v2/3cfb5ad3-49c9-44e2-ba79-f43bc20ea9ab/status'
            );
          }, 1000);
          setIsLoading(false);
        } catch (error) {
          checkErrorMsg(error);
          setIsLoading(false);
        }
      };
      fetchPaymentGopay(code);
    }
  }, [code, payment]);

  return (
    <Container>
      <Row>
        <Col md="12" sm="12">
          <Card className="shadow">
            {isLoading && <LoadingSpinner />}
            <CardHeader className="d-flex justify-content-between align-items-center">
              <CardTitle className="font-weight-bold mb-0">Payment GoPay</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="gopay">
                {payment &&
                  payment.actions.map((item) => (
                    <div>
                      {item.name === 'generate-qr-code' && (
                        <div>
                          <span>Scan QR Code</span>
                          <br />
                          <img src={item.url} className="gopay-qr-code" alt="Go Pay QR Code" />
                        </div>
                      )}
                      {item.name === 'deeplink-redirect' && (
                        <div>
                          <span>Atau</span>
                          <br />
                          <Button
                            size="md"
                            color="info"
                            type="button"
                            onClick={() => {
                              openGopay(item.url);
                            }}
                          >
                            Bayar dengan App
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TryoutNew;

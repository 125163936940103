import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  Form,
  Input,
  Button,
  CardFooter,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../LoadingSpinner';
import { checkErrorMsg, showInfoMsg } from '../../../utils/notifications';
import { sgAxios } from '../../../utils/sgAxios';
import { URLS } from '../../../constants';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }

  return '';
};

const MyCard = styled(Card)`
  max-width: 540px;
  margin: 0 auto;
`;

const RequestPasswordCard = () => {
  const form = useRef();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const resp = await sgAxios.post(URLS.PUBLIC.RESET_PASS, {
        email,
      });
      setLoading(false);
      if (resp) {
        showInfoMsg('Silahkan cek email kamu').goAway(10000);
      }
    } catch (error) {
      checkErrorMsg(error);
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <MyCard>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <CardBody className="px-4 pb-2">
            <CardTitle className="text-center mt-3 mb-5">
              <h4 className="font-weight-bolder mb-0">Mengatur ulang kata sandi akun</h4>
              <small>Silahkan masukkan email anda</small>
            </CardTitle>
            <Form onSubmit={handleSubmit} ref={form}>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ri-mail-fill text-primary" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  id="loginEmail"
                  placeholder="Alamat Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  validations={[required]}
                />
              </InputGroup>

              <Button
                type="submit"
                color="primary"
                className="w-100 my-4 py-2"
                disabled={email === ''}
              >
                {loading && <span className="spinner-border spinner-border-sm" />}
                <span>Kirim</span>
              </Button>
            </Form>
          </CardBody>
          <CardFooter className="d-flex justify-content-around px-4">
            <Link to="/login">Ke Halaman Login</Link>
          </CardFooter>
        </>
      )}
    </MyCard>
  );
};

export default RequestPasswordCard;

import React, { useState } from 'react';
import { Button, Label } from 'reactstrap';

const FieldFilled = ({ value, placeholder, label, children, handleClick }) => {
  const [showField, setShowField] = useState(false);

  return (
    <>
      {!showField && value ? (
        <div className="form-group mb-4">
          {label && <Label className="font-weight-bold">{label}</Label>}
          <div className="d-flex justify-content-between align-items-center form-control">
            <div>{placeholder}</div>
            <Button
              type="button"
              size="sm"
              color="primary"
              onClick={() => {
                setShowField(true);
                handleClick();
              }}
            >
              <i className="ri-edit-fill" />
              <span>Ubah</span>
            </Button>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default FieldFilled;

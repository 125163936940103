/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardFooter,
  Button,
  Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';

class DetailTryout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dataDetail: null,
    };
  }

  render() {
    // const { dataDetail } = this.state;
    return (
      <Container>
        <Card className="shadow mx-auto" style={{ maxWidth: '425px' }}>
          <CardHeader>
            <h5 className="font-weight-bold">Detail Tryout</h5>
          </CardHeader>
          <CardBody>
            <Container>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Nama</div>
                </Col>
                <Col xs="8">Try Out UTBK 2020 Part 1</Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Bidang</div>
                </Col>
                <Col xs="8">
                  <Badge color="info">Saintek</Badge>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Tanggal ujian</div>
                </Col>
                <Col xs="8">Senin, 11 Januari 2021</Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Jam ujian</div>
                </Col>
                <Col xs="8">
                  <strong>10.00 - 12.00 WIB</strong>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Harga</div>
                </Col>
                <Col xs="8">Rp.300.000</Col>
              </Row>
            </Container>
          </CardBody>
          <CardFooter>
            <div className="text-center">
              <Link to="/payment-success">
                <Button size="md" type="button" color="primary">
                  Beli
                </Button>
              </Link>
            </div>
          </CardFooter>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTryout);

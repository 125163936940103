import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 192px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: url('https://awsimages.detik.net.id/community/media/visual/2020/07/05/suasana-utbk-hari-pertama-di-untidar-magelang_169.mpo?w=700&q=90');
`;

const Layer = styled(Wrapper)`
  background-image: linear-gradient(
    109.6deg,
    rgba(255, 219, 47, 1) 11.2%,
    rgba(244, 253, 0, 1) 100.2%
  );
  opacity: 0.88;
  transition: all 0.5s;
`;

const Header = () => {
  return (
    <>
      <Wrapper>
        <Layer />
      </Wrapper>
    </>
  );
};

export default Header;

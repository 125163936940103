import axios from 'axios';
import Cookies from 'js-cookie';

const API_URLS = `${process.env.REACT_APP_BASE_URL}/api/v1`;

const register = (name, email, password, confirmPassword) => {
  return axios.post(`${API_URLS}/auth/register`, {
    name,
    email,
    password,
    password_confirmation: confirmPassword,
    domain: 'local',
    raw_input: 'Additional Information here',
  });
};

const login = (email, password) => {
  return axios
    .post(`${API_URLS}/auth/login`, {
      email,
      password,
    })
    .then((res) => {
      if (res.data.data.access_token) {
        Cookies.set('_tryoutuser', JSON.stringify(res.data.data), { expires: 1 });
      }
      return res.data.data;
    });
};

const logout = () => {
  Cookies.remove('_tryoutuser');
};

export default {
  register,
  login,
  logout,
};

import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Badge,
  ModalFooter,
  Button,
} from 'reactstrap';
import moment from 'moment';
import { showSuccessMsg, showErrorMsg } from '../../utils/notifications';
import { sgAxios } from '../../utils/sgAxios';
import { URLS } from '../../constants';
import history from '../../helpers/history';
import toRupiah from '../../helpers/toRupiah';

const DetailModal = ({ details, show, toggle, className }) => {
  const checkout = (id) => () => {
    try {
      const resp = sgAxios.post(URLS.USER.ORDER, {
        series_id: id,
      });
      if (resp) {
        showSuccessMsg('Tryout berhasil dicheckout');
        history.push('/dashboard/my-test');
        window.location.reload();
      }
    } catch (error) {
      showErrorMsg('😢 Gagal melakukan checkout');
    }
  };

  return (
    <>
      {details && (
        <Modal isOpen={show} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>
            <h5 className="font-weight-bold">Detail Tryout</h5>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Nama</div>
                </Col>
                <Col xs="8">{details.label}</Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Bidang</div>
                </Col>
                <Col xs="8">
                  <Badge color="info">{details.entrance_major.label}</Badge>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Tanggal ujian</div>
                </Col>
                <Col xs="8">{moment(details.exam.date).format('dddd, DD MMM YYYY')}</Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Jam ujian</div>
                </Col>
                <Col xs="8">
                  <strong>{`${details.exam.start_time} - ${details.exam.finish_time} WIB`}</strong>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <div>Harga</div>
                </Col>
                <Col xs="8">{toRupiah(details.price)}</Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <div className="text-center">
              {/* <Link to="/dashboard/waiting"> */}
              <Button onClick={checkout(details.id)} size="md" type="button" color="primary">
                Beli
              </Button>
              {/* </Link> */}
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default DetailModal;

/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Card, CardTitle, Badge, CardBody, Button } from 'reactstrap';
import moment from 'moment';
import toRupiah from '../../helpers/toRupiah';

const ContentCard = ({ status, name, regEnd, price, field, isNew, handleClick }) => {
  return (
    <Card className="shadow">
      <CardBody>
        <CardTitle>
          <h6 className="font-weight-bold text-truncate">{name}</h6>
          {field && <Badge color={field === 'Saintek' ? 'info' : 'success'}>{field}</Badge>}{' '}
          {isNew && <Badge color="danger">Baru</Badge>}
        </CardTitle>
        <div className="small mb-3">
          Tersedia sampai dengan <strong>{moment(regEnd).format('DD MMM YYYY')}</strong> pukul{' '}
          {moment(regEnd).format('HH:mm')} WIB
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="font-weight-bold">{toRupiah(price)}</div>

          {status === 'sale' && (
            <Button
              size="sm"
              color="primary"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleClick();
              }}
            >
              Beli
            </Button>
          )}

          {status === 'pending' && (
            <Button
              size="sm"
              color="danger"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleClick();
              }}
            >
              Bayar
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ContentCard;

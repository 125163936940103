import axios from 'axios';
import Cookies from 'js-cookie';
import { showErrorMsg } from './notifications';

function getAuthData() {
  const auth = Cookies.getJSON('_tryoutuser');
  const ts = Math.round(new Date().getTime() / 1000);
  if (auth && ts < auth.expired_at) {
    return auth;
  }
  return null;
}

function getAuthToken() {
  const auth = getAuthData();
  if (auth) {
    return auth.access_token;
  }
  return null;
}

function getAuthType() {
  const auth = getAuthData();
  if (auth) {
    return auth.token_type;
  }
  return null;
}

// eslint-disable-next-line import/prefer-default-export
export const sgAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${getAuthType()} ${getAuthToken()}`,
  },
});

export const checkAxiosErrResponse = (error) => {
  if (error && error.response) {
    const { status } = error.response;
    if (status && status === 401) {
      showErrorMsg('Sesi telah habis, silahkan login ulang');
      window.location.href = '/';
    }
  }
};

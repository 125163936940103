/* eslint-disable no-undef */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './helpers/scrollToTop';
import MainNavbar from './components/Navbar';
import MainFooter from './components/Footer';
import LoginPage from './views/auth/LoginPage';
import RegisterPage from './views/auth/RegisterPage';
import history from './helpers/history';
import store from './store';
import './App.css';

import HomePage from './views/HomePage';
import ProfilePage from './views/auth/ProfilePage';
import DashboardPage from './views/dashboard';
import CheckoutPage from './views/checkout/snap';
import PaymentPage from './views/payment/direct';
import DetailTryout from './views/detailTryout';
import TestView from './views/testView';
import PaymentSuccess from './views/PaymentSuccess';
import TryoutSubmitted from './views/TryoutSubmitted';
import NotFound from './views/NotFound';
import ResultDetail from './views/ResultDetail';
import ResetPass from './views/RequestPassword';
import SetPass from './views/ResetPassword';

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route path="/test/:idTest/:idQuestion/:isFirst?">
            <TestView key={document.location.href} />
          </Route>
          <Route path="/login">
            <MainNavbar />
            <LoginPage />
            <MainFooter />
          </Route>
          <Route path="/register">
            <MainNavbar />
            <RegisterPage />
            <MainFooter />
          </Route>
          <Route path="/me">
            <MainNavbar />
            <ProfilePage />
            <MainFooter />
          </Route>
          <Route path="/dashboard">
            <MainNavbar />
            <DashboardPage />
            <MainFooter />
          </Route>
          <Route path="/checkout/:slug">
            <MainNavbar />
            <CheckoutPage />
            <MainFooter />
          </Route>
          <Route path="/payment/:code">
            <MainNavbar />
            <PaymentPage />
            <MainFooter />
          </Route>
          <Route path="/detail">
            <MainNavbar />
            <DetailTryout />
            <MainFooter />
          </Route>
          <Route path="/payment-success">
            <MainNavbar />
            <PaymentSuccess />
            <MainFooter />
          </Route>
          <Route path="/test-submitted">
            <MainNavbar />
            <TryoutSubmitted />
            <MainFooter />
          </Route>
          <Route path="/result/:idTryout">
            <MainNavbar />
            <ResultDetail />
            <MainFooter />
          </Route>
          <Route path="/forget">
            <MainNavbar />
            <ResetPass />
            <MainFooter />
          </Route>
          <Route path="/reset/:token">
            <MainNavbar />
            <SetPass />
            <MainFooter />
          </Route>
          <Route exact path="/">
            <MainNavbar />
            <HomePage />
            <MainFooter />
          </Route>
          <Route>
            <MainNavbar />
            <NotFound />
            <MainFooter />
          </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </Provider>
  );
}

export default App;

/* eslint-disable react/no-array-index-key */
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import LoadingSpinner from '../../components/LoadingSpinner';
import { URLS } from '../../constants';
import { checkAxiosErrResponse, sgAxios } from '../../utils/sgAxios';

const ResultDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [summary, setSummary] = useState({});
  const { params } = useRouteMatch();

  useEffect(() => {
    const fetchResult = async () => {
      setIsLoading(true);
      try {
        const resp = await sgAxios.get(`${URLS.USER.RESULT2}/${params.idTryout}`);
        setData(get(resp, 'data.data'));
        const respSum = await sgAxios.get(`${URLS.USER.RESULT}/${params.idTryout}`);
        setSummary(get(respSum, 'data.data'));
        setIsLoading(false);
      } catch (error) {
        checkAxiosErrResponse(error);
        setIsLoading(false);
      }
    };

    fetchResult();
  }, [params]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container style={{ minHeight: '60vh' }}>
          {data && (
            <Row>
              <Col sm={12} md={4}>
                <Card body className="shadow-sm">
                  <CardTitle>
                    <h6 className="h6 font-weight-bold mb-0 border-bottom pb-2">
                      {get(summary, 'name')}
                    </h6>
                  </CardTitle>
                  <CardText>
                    {/* <h5 className="h5 font-weight-normal text-center mb-0 mt-2">
                      <Badge color="primary">Skor kamu</Badge>
                    </h5>
                    <h1 className="h1 font-weight-bold text-center" style={{ fontSize: 72 }}>
                      {get(summary, 'score')}
                    </h1> */}
                    <Table striped borderless>
                      <tbody>
                        <tr>
                          <td>Jumlah Soal</td>
                          <td className="align-right">{get(summary, 'question_count')}</td>
                        </tr>
                        <tr>
                          <td>Benar</td>
                          <td className="align-right">{get(summary, 'correct_answer')}</td>
                        </tr>
                        <tr>
                          <td>Salah</td>
                          <td className="align-right">{get(summary, 'incorrect_answer')}</td>
                        </tr>
                        <tr>
                          <td>Tidak Menjawab</td>
                          <td className="align-right">{get(summary, 'not_answer')}</td>
                        </tr>
                        <tr>
                          <td className="bold">Nilai Kamu</td>
                          <td className="align-right bold">{get(summary, 'score')}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <hr />
                    <Table bordered>
                      <tbody>
                        {get(summary, 'majors').map((item, idx) => (
                          <tr key={idx}>
                            <td>
                              <span className="result-choice">
                                Pilihan
                                {idx + 1}
                              </span>
                              <br />
                              <span className="result-major">{get(item, 'name')}</span>
                              <span className="result-status">
                                {get(item, 'status') === 'Gagal' && (
                                  <span className="status status-failed">
                                    {get(item, 'status')}
                                  </span>
                                )}
                                {get(item, 'status') === 'Lolos' && (
                                  <span className="status status-success">
                                    {get(item, 'status')}
                                  </span>
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardText>
                </Card>
              </Col>
              <Col sm={12} md={8}>
                <Card className="shadow-sm">
                  <CardHeader className="d-flex justify-content-between align-items-center bg-transparent">
                    <h6 className="h6 font-weight-bold mb-0">Hasil Tryout</h6>
                    <i className="ri-question-line" id="estimateHelp" />
                    <UncontrolledTooltip placement="auto" target="estimateHelp">
                      Hasil ini diperoleh dengan perhitungan yang sesuai dengan perhitungan
                      sebenarnya.
                    </UncontrolledTooltip>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Table striped borderless>
                      <thead>
                        <tr>
                          <th>Mata Pelajaran</th>
                          <th>B</th>
                          <th>S</th>
                          <th>K</th>
                          <th className="align-right">Nilai Mentah</th>
                          <th className="align-right">Nilai Nasional</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, idx) => (
                          <tr key={idx}>
                            <td>{get(item, 'group.name')}</td>
                            <td className="align-right">{get(item, 'correct')}</td>
                            <td className="align-right">{get(item, 'incorrect')}</td>
                            <td className="align-right">{get(item, 'not_answer')}</td>
                            <td className="align-right">{get(item, 'raw_value')}</td>
                            <td className="align-right">{get(item, 'national_value')}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <div className="text-center mt-4 mb-4">
            <Link to="/dashboard/test-result">
              <Button type="button" size="sm" color="primary">
                <i className="ri-arrow-left-line" />
                <span>Kembali</span>
              </Button>
            </Link>
          </div>
        </Container>
      )}
    </>
  );
};

export default ResultDetail;

import React from 'react';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import Avatar from 'react-avatar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ListText = styled.span`
  font-size: 13px;
`;

const UserCard = ({ user }) => {
  return (
    <Card className="shadow">
      <CardHeader className="mt-3">
        <Avatar
          className="mx-auto mb-4 d-block"
          size="100px"
          round
          color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
          name={user.name}
          src={user.avatar}
        />
        <h5 className="text-center">{user.name}</h5>
        {user.sex && (
          <ListText className="text-muted d-block text-center">
            {user.sex.id === 'l' ? 'Laki-laki' : 'Perempuan'}
          </ListText>
        )}
      </CardHeader>
      <CardBody>
        {user.entrance_major && (
          <div className="d-flex align-items-center mb-2">
            <i className="ri-search-fill mr-2 text-primary" />
            <ListText className="text-muted d-block text-center">
              {user.entrance_major.description}
            </ListText>
          </div>
        )}
        <div className="d-flex align-items-center mb-2">
          <i className="ri-mail-fill mr-2 text-primary" />
          <ListText className="font-weight-300 text-muted">{user.email}</ListText>
        </div>
        <div className="d-flex align-items-center mb-2">
          <i className="ri-phone-fill mr-2 text-primary" />
          <ListText className="font-weight-300 text-muted">
            {user.phone ? user.phone : 'Belum diisi'}
          </ListText>
        </div>
        <div className="d-flex align-items-center mb-2">
          <i className="ri-map-pin-fill mr-2 text-primary" />
          <ListText className="font-weight-300 text-muted">
            {user.school_name || 'Belum diisi'}
          </ListText>
        </div>

        <Link to="/me">
          <Button className="w-100 mt-4" type="button" color="primary">
            Ubah Profil
          </Button>
        </Link>
      </CardBody>
    </Card>
  );
};

export default UserCard;

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import DashboardLayout from '../../layouts/DashboardLayout';
import TryoutNew from './TryoutNew';
import TryoutWaiting from './TryoutWaiting';
import TryoutMine from './TryoutMine';
import TryoutResult from './TryoutResult';

const DashboardUser = ({ currentUser }) => {
  const { path, url } = useRouteMatch();

  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  return (
    <DashboardLayout currentUser={currentUser} url={url}>
      <Switch>
        <Route path={`${path}/waiting`}>
          <TryoutWaiting />
        </Route>
        <Route path={`${path}/my-test`}>
          <TryoutMine />
        </Route>
        <Route path={`${path}/test-result`}>
          <TryoutResult />
        </Route>
        <Route path={path}>
          <TryoutNew />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'auth.user'),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardUser);

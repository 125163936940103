import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  Form,
  Input,
  Button,
  CardFooter,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner';

import { register, login } from '../../../actions/auth';
import history from '../../../helpers/history';

const MyCard = styled(Card)`
  max-width: 540px;
  margin: 0 auto;
`;

const RegisterCard = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSuccess(false);

    dispatch(register(name, email, password, confirmPassword))
      .then(() => {
        setIsSuccess(true);
        setIsLoading(true);
        dispatch(login(email, password)).then(() => {
          history.push('/me');
        });
      })
      .catch(() => {
        setIsSuccess(false);
        setIsLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <MyCard>
          <CardBody className="px-4 px-md-5 pb-2">
            <CardTitle className="text-center mt-3 mb-5">
              <h4 className="font-weight-bolder mb-0">Daftar Sekarang</h4>
              <small>Dan dapatkan akses tryout dan latihan soalnya </small>
            </CardTitle>
            <Form onSubmit={handleSubmit}>
              {!isSuccess && (
                <>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ri-user-3-fill text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Nama lengkap"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ri-mail-fill text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      placeholder="Alamat email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ri-lock-fill text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      id="loginPass"
                      placeholder="Kata sandi"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ri-lock-fill text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      id="loginPass"
                      placeholder="Ketik ulang kata sandi"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </InputGroup>
                  <Button type="submit" color="primary" className="w-100 my-4 py-2">
                    Daftar
                  </Button>
                </>
              )}
              {message && (
                <div className="form-group">
                  <div
                    className={isSuccess ? 'alert alert-success' : 'alert alert-danger'}
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </CardBody>
          <CardFooter className="d-flex justify-content-end px-4">
            <Link to="/login">Sudah punya akun?</Link>
          </CardFooter>
        </MyCard>
      )}
    </>
  );
};

export default RegisterCard;

import React from 'react';
import ResetPasswordCard from '../components/Auth/ResetPasswordCard';

const ResetPassword = () => {
  return (
    <div>
      <ResetPasswordCard />
    </div>
  );
};

export default ResetPassword;

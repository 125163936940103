import React from 'react';
import styled from 'styled-components';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledCollapse,
  Row,
  Col,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import Header from '../Header';

import { logout } from '../../actions/auth';

const Username = styled.span`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Logo = styled.img`
  width: 100%;
  height: 95px !important;
`;

const MainNavbar = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div>
      <Header />
      <Navbar className="navbar-horizontal bg-transparent" expand="lg">
        <Container>
          <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
            <Logo
              alt="..."
              src="https://salembagroup.com/wp-content/uploads/2020/06/cropped-logo-3-1.png"
            />
          </NavbarBrand>
          <button
            aria-controls="navbar-default"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-default"
            data-toggle="collapse"
            id="navbar-default"
            type="button"
          >
            <span className="navbar-toggler-icon">
              <i className="ri-menu-3-line" />
            </span>
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-default">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src="https://salembagroup.com/wp-content/uploads/2020/06/cropped-logo-3-1.png"
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-default"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-default"
                    data-toggle="collapse"
                    id="navbar-default"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto align-items-center" navbar>
              <NavItem>
                <NavLink href="/">Beranda</NavLink>
              </NavItem>
              {!currentUser && (
                <>
                  <NavItem>
                    <NavLink href="/login">Masuk</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/register">Daftar</NavLink>
                  </NavItem>
                </>
              )}
              {currentUser && (
                <>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <Avatar
                        size="32px"
                        round
                        color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                        name={currentUser.user.name}
                        src={currentUser.user.avatar}
                      />
                      <Username className="mx-1">{currentUser.user.name}</Username>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <NavLink href="/dashboard">Dashboard</NavLink>
                      <NavLink href="" onClick={logOut}>
                        Logout
                      </NavLink>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MainNavbar;

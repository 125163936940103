/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Swiper, Navigation, Pagination } from 'swiper/swiper.esm';
import { get } from 'lodash';
import ContentCard from '../cards/ContentCard';
import { sgAxios } from '../../utils/sgAxios';
import { URLS } from '../../constants';
// import { showErrorMsg } from '../../utils/notifications';

const ContentSlider = () => {
  const [dataSeries, setDataSeries] = useState([]);

  const fetchSeries = async () => {
    try {
      const resp = await sgAxios.get(`${URLS.USER.GET_SERIES}/active?page=1&size=10`);
      const respData = get(resp, 'data.data');
      setDataSeries(respData);
    } catch (eres) {
      // showErrorMsg('😢 Gagal mendapatkan data series');
    }
  };

  useEffect(() => {
    fetchSeries();
  }, []);

  const params = {
    // Provide Swiper class as props
    Swiper,
    // Add modules you need
    modules: [Navigation, Pagination],
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1440: {
        slidesPerView: 4,
        spaceBetween: 12,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 12,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 12,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 12,
      },
    },
  };
  return (
    <ReactIdSwiperCustom {...params}>
      {dataSeries.map((item) => (
        <div key={item.id} className="mb-5">
          <ContentCard
            name={item.label}
            regEnd={item.effective_until}
            price={item.price}
            field={item.entrance_major.label}
            // isNew={item.isNew}
          />
        </div>
      ))}
    </ReactIdSwiperCustom>
  );
};

export default ContentSlider;

import React from 'react';
import RegisterCard from '../../components/Auth/RegisterCard';

const LoginPage = () => {
  return (
    <div>
      <RegisterCard />
    </div>
  );
};

export default LoginPage;

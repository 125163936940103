import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import { useSelector } from 'react-redux';
import LoginCard from '../components/Auth/LoginCard';
import UserCard from '../components/Auth/UserCard';
import BannerSlider from '../components/BannerSlider';
import ContentSlider from '../components/ContentSlider';

function HomePage() {
  const { user: currentUser } = useSelector((state) => state.auth);
  return (
    <>
      <Container className="pt-4">
        <Row>
          <Col md="8">
            <div className="shadow mb-2">
              <BannerSlider />
            </div>
          </Col>
          <Col md="4" className="px-3 mb-2">
            <div className="shadow">
              {currentUser ? <UserCard user={currentUser.user} /> : <LoginCard />}
            </div>
          </Col>
        </Row>
        {currentUser && (
          <div className="mt-5">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5 className="font-weight-bold">Tryout Terbaru</h5>
              <Button size="sm" type="button" color="primary">
                Lihat semua
              </Button>
            </div>
            <ContentSlider />
          </div>
        )}
      </Container>
    </>
  );
}

export default HomePage;

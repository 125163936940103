/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import Avatar from 'react-avatar';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import axios from 'axios';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { URLS } from '../../constants';
import { sgAxios } from '../../utils/sgAxios';

import { showSuccessMsg, showErrorMsg } from '../../utils/notifications';
import FieldFilled from '../../components/FieldFilled';

const ProfilePage = (props) => {
  const [birthdate, setBirthdate] = useState(null);
  const [classs, setGrades] = useState([]);
  const [majors, setMajors] = useState([]);
  const [programStudies, setPogramStudies] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [entrances, setEntrances] = useState([]);
  const [programGroups, setProgramgroups] = useState([]);
  const [fileName, setFileName] = useState('');
  const [fileData, setFileData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [userData, setUserData] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [sexs, setSexs] = useState([]);

  const { user: currentUser } = useSelector((state) => state.auth);

  const phoneRegexp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const formStyle = {
    control: (provided) => ({
      ...provided,
      fontSize: '.875rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '6.5px 12px',
      color: '#8898aa',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#8898aa',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#8898aa',
    }),
  };

  const userValidationSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, 'nama terlalu sedikit!')
      .max(50, 'Nama terlalu panjang!')
      .required('Nama harus diisi.'),
    sex: yup.object().required('Jenis kelamin wajib diisi.'),
    // place_of_birth: yup.string().required('Kota lahir harus diisi.'),
    birth_date: yup
      .string()
      .test('DOB', 'Umur tidak memenuhi persyaratan(min. 16 th).', (value) => {
        return moment().diff(moment(value), 'years') >= 16;
      })
      .required('Tanggal lahir wajib diisi.'),
    email: yup.string().email('Email tidak valid.').required('Email wajib diisi.'),
    phone: yup
      .string('Nomor harus berupa angka.')
      .matches(phoneRegexp, 'Nomor Telepon tidak valid.')
      .required('Nomor Telepon wajib diisi.'),
    entrance_major: yup.object().required('Jalur masuk wajib diisi.'),
    class: yup.object().required('Kelas wajib diisi.'),
    major: yup.object().required('Jurusan wajib diisi.'),
    study_program_group: yup.object().required('Group wajib diisi.'),
    province: yup.object().required('Provinsi harus diisi.'),
    city: yup.object().required('Kota harus diisi.'),
    school_name: yup.string().required('Nama sekolah harus diisi.'),
    choose1: yup.object().required('Pilihan 1 wajib diisi.'),
    choose2: yup.object().required('Pilihan 2 wajib diisi.'),
    choose3: yup.object().required('Pilihan 3 wajib diisi.'),
    graduation_year: yup.number().required('Tahun lulus harus diisi.'),
  });

  const fetchSex = async () => {
    const resp = await axios.get(URLS.PUBLIC.GET_SEX);
    const sexsResp = get(resp, 'data.data').map((item) => ({
      ...item,
      value: item.id,
    }));
    setSexs(sexsResp);
  };

  // const fetchSchool = async (code) => {
  //   const resp = await axios.get(`${URLS.PUBLIC.GET_SCHOOL}?city=${code}`);
  //   const school = get(resp, 'data.data').map((item) => ({
  //     ...item,
  //     label: item.name,
  //     value: item.id,
  //   }));
  //   setSchools(school);
  // };

  const fetchClass = async () => {
    const resp = await axios.get(URLS.PUBLIC.GET_GRADES);
    const classData = get(resp, 'data.data').map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));
    setGrades(classData);
  };

  const fetchMajor = async () => {
    const resp = await axios.get(URLS.PUBLIC.GET_MAJORS);
    const majorData = get(resp, 'data.data').map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));
    setMajors(majorData);
  };

  const fetchProgramStudy = async (groupId, emajorId) => {
    const resp = await axios.get(
      `${URLS.PUBLIC.GET_STUDY_PROGRAMS}?group=${groupId}&major=${emajorId}`
    );
    if (groupId && emajorId) {
      // setEntranceMajorGroup();
    }
    const programData = get(resp, 'data.data').map((item) => ({
      ...item,
      value: item.id,
    }));
    setPogramStudies(programData);
  };

  const fetchProvince = async () => {
    const resp = await axios.get(URLS.PUBLIC.GET_PROVINCES);
    const provinceData = get(resp, 'data.data').map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));

    setProvinces(provinceData);
  };

  const fetchCity = async (id) => {
    const resp = await axios.get(`${URLS.PUBLIC.GET_CITIES}/${id}`);
    const cityData = get(resp, 'data.data').map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));

    setCities(cityData);
  };

  const fetchEntrance = async () => {
    const resp = await sgAxios.get(URLS.PUBLIC.GET_ENTRANCE_OPTIONS);
    const entrancesData = get(resp, 'data.data').map((item) => ({
      ...item,
      label: item.label,
      value: item.id,
    }));
    setEntrances(entrancesData);
  };

  const fetchProgramGroup = async () => {
    const resp = await sgAxios.get(URLS.PUBLIC.GET_PROGRAM_GROUP);
    const programs = get(resp, 'data.data').map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }));
    setProgramgroups(programs);
  };

  const refreshToken = async () => {
    try {
      const resp = await sgAxios.put(URLS.USER.PUT_REFRESH);
      if (resp) {
        Cookies.set('_tryoutuser', JSON.stringify(resp.data.data));
      }
    } catch (eres) {
      showErrorMsg('Gagal merefresh data');
      console.log(error);
    }
  };

  const formSubmit = async (values) => {
    const { history } = props;
    if (fileData) {
      // eslint-disable-next-line no-param-reassign
      values.avatar = fileData.url;
    }
    try {
      const resp = await sgAxios.put(URLS.USER.SET_PROFILE, values);
      if (resp) {
        refreshToken();
        showSuccessMsg('👏 Data berhasil disimpan.');
        setTimeout(() => {
          history.push('/dashboard');
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      showErrorMsg('😢 Gagal menyimpan data.');
      console.log(error);
    }
  };

  const handleFileInput = async (e) => {
    setIsUploading(true);
    const formData = new FormData();
    const imagefile = e.target;
    setFileName(imagefile.files[0]);
    formData.append('file', imagefile.files[0]);
    formData.append('domain', 'avatars');
    try {
      const resp = await sgAxios.post(URLS.USER.UPLOAD_FILE, formData);
      const respData = get(resp, 'data.data');
      setFileData(respData);
      setIsUploading(false);
      showSuccessMsg('👏 File berhasil tersimpan');
    } catch (error) {
      setIsUploading(false);
      showErrorMsg('😢 File gagal diupload');
    }
  };

  const fetchUserData = async () => {
    try {
      const resp = await sgAxios.get(URLS.USER.GET_ME);
      const respData = get(resp, 'data.data');
      setSelectedSchool(respData.school_name);
      setUserData(respData);
    } catch (error) {
      console.log(error);
      showErrorMsg('😢 Gagal mendapatkan data user');
    }
  };

  useEffect(() => {
    fetchSex();
    fetchUserData();
    fetchEntrance();
    fetchProgramGroup();
    fetchProvince();
    fetchClass();
    fetchMajor();
    fetchProgramStudy(
      get(currentUser, 'user.entrance_major.id'),
      get(currentUser, 'user.study_program_group.id')
    );

    if (get(currentUser, 'user.province')) {
      const provinceId = get(currentUser, 'user.province.id');
      fetchCity(provinceId);
    }
  }, [currentUser]);

  if (!currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Container>
        <Card>
          <CardHeader>
            <CardTitle>
              <h5 className="font-weight-bold my-2">Edit Profil</h5>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={{
                name: get(userData, 'name') || get(currentUser, 'user.name'),
                sex: get(userData, 'sex') || get(currentUser, 'user.sex') || '',
                birth_date: get(userData, 'birth_date') || '',
                email: get(userData, 'email') || get(currentUser, 'user.email'),
                phone: get(userData, 'phone') || '',
                avatar: get(userData, 'avatar') || '',
                entrance_major: get(userData, 'entrance_major') || '',
                class: get(userData, 'class') || '',
                major: get(userData, 'major') || '',
                study_program_group: get(userData, 'study_program_group') || '',
                province: get(userData, 'province') || '',
                city: get(userData, 'city') || '',
                school_name: get(userData, 'school_name') || '',
                choose1: get(userData, 'choose1') || '',
                choose2: get(userData, 'choose2') || '',
                choose3: get(userData, 'choose3') || '',
                graduation_year: get(userData, 'graduation_year') || '',
              }}
              validationSchema={userValidationSchema}
              onSubmit={formSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Container>
                    <Row>
                      <Col sm="12" md="4">
                        {userData && (
                          <>
                            {!fileData ? (
                              <Avatar
                                className="mx-auto mb-4 d-block"
                                size="100px"
                                round
                                color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                                src={get(userData, 'avatar')}
                                name={get(userData, 'name')}
                              />
                            ) : (
                              <img
                                className="mx-auto mb-4 d-block"
                                style={{ width: '100px' }}
                                src={fileData.url}
                                alt="Foto"
                              />
                            )}
                          </>
                        )}
                      </Col>
                      <Col sm="12" md="6">
                        <h6 className="font-weight-bold mb-1">Ubah Foto Profil</h6>
                        <div className="custom-file">
                          {isUploading ? (
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <label className="custom-file-label" htmlFor="customFileLang">
                              {fileName.name || 'Pilih foto'}
                            </label>
                          )}
                          <input
                            className="custom-file-input"
                            id="customFileLang"
                            lang="en"
                            type="file"
                            placeholder="ganti"
                            onChange={(e) => {
                              handleFileInput(e);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    {userData && !userData.data_ok && (
                      <div className="err-profile">
                        <span className="text-danger text-bold center">
                          Silahkan lengkapi data profile terlebih dahulu, sebelum melanjutkan
                        </span>
                      </div>
                    )}
                    <hr style={{ marginTop: '10px' }} />
                    <Row>
                      <Col md="4" sm="12">
                        <h6 className="font-weight-bold">Data Pribadi</h6>
                        <hr className="border-bottom" />
                        <FormGroup className="mb-4">
                          <Label className="form-control-label font-weight-bold">
                            Nama lengkap
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Masukkan nama lengkap"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {errors.name && touched.name && (
                            <div className="text-danger small">{errors.name}</div>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Jenis kelamin</Label>
                          <Select
                            className="basic-single"
                            name="sexsOption"
                            styles={formStyle}
                            value={sexs ? sexs.find((item) => item.value === values.sex.id) : ''}
                            onChange={(e) => {
                              setFieldValue('sex', e);
                            }}
                            options={sexs}
                            placeholder="Pilih Jenis Kelamin"
                          />
                          {errors.sex && <div className="text-danger small">{errors.sex}</div>}
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Tanggal lahir</Label>
                          <FieldFilled
                            value={values.birth_date}
                            placeholder={values.birth_date}
                            handleClick={() => console.log('changed')}
                          >
                            <DatePicker
                              autoComplete="off"
                              style={{ width: '100%' }}
                              timePicker={false}
                              dateFormat="dd MMM yyyy"
                              selected={birthdate}
                              name="birth_date"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              onChange={(value) => {
                                setBirthdate(value);
                                setFieldValue('birth_date', moment(value).format('YYYY-MM-DD'));
                              }}
                              className="form-control w-100 datepicker"
                              placeholderText="dd mmm yyyy"
                            />
                          </FieldFilled>
                          {errors.birth_date && (
                            <div className="text-danger small">{errors.birth_date}</div>
                          )}
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label htmlFor="editEmail" className="font-weight-bold">
                            Alamat email
                          </Label>
                          <Input
                            type="email"
                            id="editEmail"
                            name="email"
                            placeholder="Masukkan alamat email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <div className="text-danger small">{errors.email}</div>
                          )}
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label htmlFor="editPhone" className="font-weight-bold">
                            Nomor telepon
                          </Label>
                          <Input
                            type="tel"
                            id="editPhone"
                            name="phone"
                            placeholder="Masukkan nomor telepon"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                          {errors.phone && touched.phone && (
                            <div className="text-danger small">{errors.phone}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="12">
                        <h6 className="font-weight-bold">Asal Sekolah</h6>
                        <hr className="border-bottom" />

                        <FieldFilled
                          label="Nama Sekolah"
                          value={values.school_id}
                          placeholder={selectedSchool}
                          handleClick={() => {
                            fetchCity(values.province.id);
                          }}
                        >
                          <FormGroup className="mb-4">
                            <Label className="font-weight-bold">Provinsi</Label>
                            <Select
                              className="basic-single"
                              name="provinceOption"
                              styles={formStyle}
                              value={
                                provinces
                                  ? provinces.find((item) => item.value === values.province.id)
                                  : ''
                              }
                              onChange={(e) => {
                                setFieldValue('province', e);
                                fetchCity(e.value);
                              }}
                              options={provinces}
                              placeholder="Pilih provinsi"
                            />

                            {/* {values.province} */}
                            {errors.province && (
                              <div className="text-danger small">{errors.province}</div>
                            )}
                          </FormGroup>

                          <FormGroup className="mb-4">
                            <Label className="font-weight-bold">Kota</Label>
                            <Select
                              className="basic-single"
                              name="cityOption"
                              styles={formStyle}
                              value={
                                cities ? cities.find((item) => item.value === values.city.id) : ''
                              }
                              onChange={(e) => {
                                setFieldValue('city', e);
                              }}
                              options={cities}
                              placeholder="Pilih kota"
                            />
                            {errors.city && <div className="text-danger small">{errors.city}</div>}
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Label className="font-weight-bold">Nama Sekolah</Label>
                            <Input
                              type="text"
                              id="editSchool"
                              name="school_name"
                              placeholder="Masukkan nama sekolah"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.school_name}
                            />
                            {errors.school_name && (
                              <div className="text-danger small">{errors.school_name}</div>
                            )}
                          </FormGroup>
                        </FieldFilled>

                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Kelas</Label>
                          <Select
                            className="basic-single"
                            name="classOption"
                            styles={formStyle}
                            value={
                              classs ? classs.find((item) => item.value === values.class.id) : ''
                            }
                            onChange={(e) => {
                              setFieldValue('class', e);
                            }}
                            options={classs}
                            placeholder="Pilih kelas"
                          />
                          {errors.class && <div className="text-danger small">{errors.class}</div>}
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Jurusan</Label>
                          <Select
                            className="basic-single"
                            name="majorOption"
                            styles={formStyle}
                            value={
                              majors ? majors.find((item) => item.value === values.major.id) : ''
                            }
                            onChange={(e) => {
                              setFieldValue('major', e);
                            }}
                            options={majors}
                            placeholder="Pilih jurusan"
                          />
                          {errors.major && <div className="text-danger small">{errors.major}</div>}
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Tahun lulus</Label>
                          <Input
                            type="number"
                            name="graduation_year"
                            placeholder="2020"
                            value={values.graduation_year}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.graduation_year && (
                            <div className="text-danger small">{errors.graduation_year}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="12">
                        <h6 className="font-weight-bold">Program Studi Pilihan</h6>
                        <hr className="border-bottom" />
                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Jalur Masuk</Label>
                          <Select
                            className="basic-single"
                            name="schoolOption"
                            styles={formStyle}
                            value={
                              entrances
                                ? entrances.find((item) => item.value === values.entrance_major.id)
                                : ''
                            }
                            onChange={(e) => {
                              setFieldValue('entrance_major', e);
                              // fetchFaculty(e.value);
                              fetchProgramStudy(values.study_program_group.id, e.id);
                            }}
                            options={entrances}
                            placeholder="Pilih jalur masuk"
                          />
                          {errors.entrance_major && (
                            <div className="text-danger small">{errors.entrance_major}</div>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Group</Label>
                          <Select
                            className="basic-single"
                            name="facultyOption"
                            styles={formStyle}
                            value={
                              programGroups
                                ? programGroups.find(
                                    (item) => item.value === values.study_program_group.id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              setFieldValue('study_program_group', e);
                              fetchProgramStudy(e.id, values.entrance_major.id);
                            }}
                            options={programGroups}
                            placeholder="Pilih group"
                          />
                          {errors.study_program_group && (
                            <div className="text-danger small">{errors.study_program_group}</div>
                          )}
                        </FormGroup>
                        {errors.choose}
                        <div className="text-danger small">
                          Pilih Jalur Masuk dan Group Terlebih Dahulu
                        </div>
                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Pilihan 1</Label>
                          <Select
                            className="basic-single"
                            name="facultyOption"
                            styles={formStyle}
                            value={
                              programStudies
                                ? programStudies.find((item) => item.value === values.choose1.id)
                                : ''
                            }
                            onChange={(e) => {
                              setFieldValue('choose1', e);
                            }}
                            options={programStudies.filter(
                              (item) =>
                                item.value !== values.choose3.id && item.value !== values.choose2.id
                            )}
                            placeholder="Pilih program studi"
                          />
                          {errors.choose1 && (
                            <div className="text-danger small">{errors.choose1}</div>
                          )}
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Pilihan 2</Label>
                          <Select
                            className="basic-single"
                            name="facultyOption"
                            styles={formStyle}
                            value={
                              programStudies
                                ? programStudies.find((item) => item.value === values.choose2.id)
                                : ''
                            }
                            onChange={(e) => {
                              setFieldValue('choose2', e);
                            }}
                            options={programStudies.filter(
                              (item) =>
                                item.value !== values.choose1.id && item.value !== values.choose3.id
                            )}
                            placeholder="Pilih program studi"
                          />
                          {errors.choose2 && (
                            <div className="text-danger small">{errors.choose2}</div>
                          )}
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="font-weight-bold">Pilihan 3</Label>
                          <Select
                            className="basic-single"
                            name="facultyOption"
                            styles={formStyle}
                            value={
                              programStudies
                                ? programStudies.find((item) => item.value === values.choose3.id)
                                : ''
                            }
                            onChange={(e) => {
                              setFieldValue('choose3', e);
                            }}
                            options={programStudies.filter(
                              (item) =>
                                item.value !== values.choose1.id && item.value !== values.choose2.id
                            )}
                            placeholder="Pilih program studi"
                          />
                          {errors.choose3 && (
                            <div className="text-danger small">{errors.choose3}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-center border-top border-secondary pt-4">
                      <Button type="submit" color="primary">
                        Simpan
                      </Button>
                    </div>
                  </Container>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default withRouter(ProfilePage);

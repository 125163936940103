import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingSpinner = () => {
  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center loader"
      style={{ height: '100%' }}
    >
      <Spinner color="primary" />
    </div>
  );
};

export default LoadingSpinner;

/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import styled from 'styled-components';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import classnames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { sgAxios } from '../../utils/sgAxios';
import Countdown from '../../components/Countdown';
import LoadingSpinner from '../../components/LoadingSpinner';
import ModalSubmit from '../../components/Modals/SubmitAnswer';
import { URLS } from '../../constants';
import { checkErrorMsg, showErrorMsg, showWarningMsg } from '../../utils/notifications';
import history from '../../helpers/history';

const TestNav = styled.div`
  background: #009dce;
  font-weight: bold;
  line-height: 28px;
`;

const SubmitBtn = styled(Button)`
  background: #009dce;
  border: none;
  width: 100%;
  font-size: 12px;
`;

const ListofQuestion = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
`;

const QuestionItem = styled.div`
  background: #ffffff;
  border: 1px solid #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 4px;
  cursor: pointer;
  min-width: 36px;
  height: 32px;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-1px);
  }
`;

const TestView = () => {
  const { idTest, idQuestion, isFirst } = useParams();
  const [showHelp, setShowHelp] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [total, setTotal] = useState(0);
  const [subject, setSubject] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);
  const [myAnswer, setMyAnswer] = useState(null);
  const [doubts, setDoubts] = useState([]);
  const [isntruction, setInstruction] = useState('-');
  const [isLoading, setIsLoading] = useState(false);
  const [startAt, setStartAt] = useState(null);
  const [questions, setQuestions] = useState([]);

  const toggle = () => setShowHelp(!showHelp);

  const saveAnswers = async (data) => {
    reactLocalStorage.setObject(`__sg_answers-${idTest}`, data);
  };

  const getAnswers = () => {
    const answers = reactLocalStorage.get(`__sg_answers-${idTest}`) || '[]';
    return JSON.parse(answers);
  };

  const clearAnswers = () => {
    reactLocalStorage.remove(`__sg_answers-${idTest}`);
  };

  const saveDoubts = async (data) => {
    reactLocalStorage.setObject(`__sg_doubts-${idTest}`, data);
  };

  const getDoubts = () => {
    const data = reactLocalStorage.get(`__sg_doubts-${idTest}`) || '[]';
    return JSON.parse(data);
  };

  const clearDoubts = () => {
    reactLocalStorage.remove(`__sg_doubts-${idTest}`);
  };

  const fetchInstructions = async () => {
    try {
      const resp = await sgAxios.get(`/api/v1/series-instruction/${idTest}`);
      setInstruction(resp.data.data.content);
      toggle();
    } catch (error) {
      console.log(error);
    }
  };

  const generateWorkOn = async () => {
    try {
      const resp = await sgAxios.post(`/api/v1/tryout/work-on/${idTest}`);
      setQuestions(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getWorkOn = async () => {
    try {
      const resp = await sgAxios.get(`/api/v1/tryout/work-on/${idTest}`);
      setQuestions(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const setDoubt = () => {
    const datas = getDoubts() || [];

    if (datas.includes(idQuestion)) {
      return;
    }
    datas.push(idQuestion);
    showWarningMsg('Ditandai sebagai jawaban ragu - ragu.');
    setDoubts(datas);
    saveDoubts(datas);
  };

  const clearQuestion = async () => {
    const answer = myAnswer.find((itm) => itm.question_number === parseInt(idQuestion));
    const indexOf = myAnswer.indexOf(answer);

    answer.question_answer_id = null;
    answer.answer_id = -19;
    answer.question_id = null;
    answer.series_detail_id = null;

    myAnswer[indexOf] = answer;

    setMyAnswer(myAnswer);
    saveAnswers(myAnswer);
    showWarningMsg('Jawaban dibatalkan');
    history.replace(`/test/${idTest}/${parseInt(idQuestion)}`);
    await window.location.reload();
  };

  const setSure = () => {
    const datas = getDoubts();

    const idx = datas.indexOf(idQuestion);
    datas.splice(idx, 1);

    showWarningMsg('Sudah yakin.');
    saveDoubts(datas);
    setDoubts(datas);
  };

  const postAnswer = async (event) => {
    const data = JSON.parse(event.target.value);
    const answerItem = {
      question_number: parseInt(idQuestion),
      series_detail_id: parseInt(data.series_detail_id),
      answer_id: parseInt(data.order),
      question_id: parseInt(data.question_id),
      question_answer_id: parseInt(data.id),
    };

    const saved = myAnswer;
    const doubt = getDoubts();
    const temp =
      saved && saved.filter((item) => item.question_number !== answerItem.question_number);
    const newPayload = saved && {
      answers: [...temp, answerItem],
      doubts: doubt,
      start_at: startAt,
    };
    try {
      const payload = newPayload || {
        answers: [answerItem],
        user_tryout_id: 0,
        doubts: doubt,
        start_at: startAt,
      };
      const resp = await sgAxios.post(`${URLS.USER.GET_TRYOUT}/answer/${idTest}`, payload);
      const respData = get(resp, 'data.data');
      if (!respData) {
        return;
      }
      saveAnswers(respData.answers);
      setMyAnswer(respData.answers);
    } catch (error) {
      console.log(error);
    }
  };

  const prevQuestion = async () => {
    history.replace(`/test/${idTest}/${parseInt(idQuestion) - 1}`);
    await window.location.reload();
  };

  const nextQuestion = async () => {
    history.replace(`/test/${idTest}/${parseInt(idQuestion) + 1}`);
    await window.location.reload();
  };

  const submitAllAnswers = async () => {
    try {
      setIsLoading(true);
      const payload = getAnswers();
      const resp = await sgAxios.put(`${URLS.USER.GET_TRYOUT}/submit/${idTest}`, {
        answers: payload,
      });
      setShowSubmit(false);
      if (resp) {
        clearAnswers();
        clearDoubts();
        history.replace('/test-submitted');
        await window.location.reload();
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.data.user_message === 'ERR::DONE') {
        clearAnswers();
        clearDoubts();
        history.replace('/test-submitted');
        await window.location.reload();
        setIsLoading(false);
      } else {
        showErrorMsg('😢 Gagal mengumpulkan ujian');
      }
      setIsLoading(false);
      setShowSubmit(false);
    }
  };

  useEffect(() => {
    const getUserAnswers = async () => {
      const resp = await sgAxios.get(`${URLS.USER.USER_ANSWER}/${idTest}`);
      const data = get(resp, 'data.data');
      if (data && data.length <= 0) {
        setStartAt(moment());
        return;
      }

      if (!data) {
        setStartAt(moment());
        return;
      }

      const ldoubts = data.doubts;
      setMyAnswer(data.answers);
      if (ldoubts) {
        setDoubts(ldoubts);
        saveDoubts(ldoubts);
      }
      saveAnswers(data.answers);
    };
    const startExam = async () => {
      await sgAxios.post(`${URLS.USER.GET_TRYOUT}/answer/${idTest}`, { first: true });
      clearAnswers();
      clearDoubts();
    };

    if (isFirst) {
      fetchInstructions();
      // setShowHelp(true);
      getUserAnswers();
      startExam();
      generateWorkOn();
    } else {
      setMyAnswer(getAnswers());
      setDoubts(getDoubts());
      getWorkOn();
    }

    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const resp = await sgAxios.get(
          `${URLS.USER.GET_TRYOUT}/try/${idTest}?page=${idQuestion}&size=1`
        );
        const respData = get(resp, 'data.data');
        setDataSource(respData);
        setTotal(get(resp, 'data.paginate.total'));
        respData.items.map((item) => {
          setSubject(item.subject.name);
          return null;
        });
        setIsLoading(false);
      } catch (error) {
        checkErrorMsg(error);
        setIsLoading(false);
      }
    };

    fetchDetail();
  }, [idQuestion, idTest, isFirst]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <TestNav className="text-white py-4 px-2">
            <Container>
              <Row className="d-flex justify-content-between">
                <div className="col col-sm-6">
                  <Row>
                    <Col sm="3" className="font-weight-light">
                      Tryout
                    </Col>
                    <Col className="text-truncate">{dataSource && dataSource.series.label}</Col>
                  </Row>
                  <Row>
                    <Col sm="3" className="font-weight-light">
                      Bidang
                    </Col>
                    <Col>
                      {dataSource &&
                        dataSource.series &&
                        dataSource.series.entrance_major &&
                        dataSource.series.entrance_major.label}
                    </Col>
                  </Row>
                </div>
                <div className="col col-sm-6 text-right">
                  <Row className="justify-content-between">
                    <Col sm="7" className="font-weight-light">
                      Tanggal
                    </Col>
                    <Col sm="5">
                      {dataSource &&
                        dataSource.series &&
                        moment(dataSource.series.exam_start_at).format('DD MMM YYYY')}
                    </Col>
                  </Row>
                  <Row className="justify-content-between">
                    <Col sm="7" className="font-weight-light">
                      Waktu Tersisa
                    </Col>
                    <Col sm="5">
                      {dataSource && dataSource.series && (
                        <Countdown
                          timeTillDate={dataSource.series.exam_finish_at}
                          timeFormat="YYYY-MM-DD HH:mm:ss"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Row>
            </Container>
          </TestNav>
          <Container>
            <Row className="py-4">
              <Col md="8" sm="12">
                <Card className="shadow-sm">
                  <CardHeader className="d-flex justify-content-between align-items-center">
                    <div className="h6 font-weight-bold">
                      {`Soal No. ${idQuestion}`}
                      <br />
                      <small>{subject}</small>
                    </div>
                    <Button
                      onClick={fetchInstructions}
                      type="button"
                      size="sm"
                      className="d-flex align-items-center btn btn-secondary"
                    >
                      <i className="ri-question-line mr-2" />
                      <span>Petunjuk</span>
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div className="question-container">
                      <Form>
                        <div>
                          {dataSource && dataSource.items.length > 0 && (
                            <span
                              dangerouslySetInnerHTML={{ __html: dataSource.items[0].question }}
                            />
                          )}
                        </div>
                        <ol type="A" style={{ margin: '20px 0 0 -20px' }}>
                          {dataSource &&
                            dataSource.items.length > 0 &&
                            dataSource.items[0].answers.map((item) => (
                              <li key={item.id}>
                                <div
                                  className="custom-control custom-radio mb-3 answer-item"
                                  key={item.id}
                                >
                                  <input
                                    onChange={postAnswer}
                                    name="custom-radio"
                                    className="custom-control-input"
                                    id={`customRadio${item.id}`}
                                    type="radio"
                                    value={JSON.stringify(item)}
                                    checked={
                                      myAnswer &&
                                      myAnswer.find((itm) => itm.question_answer_id === item.id)
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customRadio${item.id}`}
                                  >
                                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                                  </label>
                                </div>
                              </li>
                            ))}
                        </ol>
                      </Form>
                    </div>
                    <h6 className="h6 font-weight-bold">Navigasi Soal</h6>
                    <div className="nav-question">
                      {idQuestion !== '1' && (
                        // <Link to={`/test/${idTest}/${parseInt(idQuestion) - 1}`}>
                        <Button
                          onClick={prevQuestion}
                          type="button"
                          color="primary"
                          className="col-md-1 btn-tryout-nav"
                        >
                          {/* <i className="ri-arrow-left-s-line" /> */}
                          <img src="/images/previous.svg" alt="Sebelumnya" />
                        </Button>
                        // </Link>
                      )}
                      {idQuestion < total && (
                        <Button
                          onClick={nextQuestion}
                          type="button"
                          color="primary"
                          className="col-md-1 btn-tryout-nav"
                        >
                          {/* <i className="ri-arrow-right-s-line" /> */}
                          <img src="/images/next.svg" alt="Selanjutnya" />
                        </Button>
                      )}
                      {!doubts.find((itm) => itm === idQuestion) &&
                        myAnswer &&
                        myAnswer.find(
                          (item) =>
                            `${item.question_number}` === idQuestion &&
                            item.question_answer_id != null
                        ) && (
                          <Button
                            onClick={setDoubt}
                            type="button"
                            color="default"
                            className="col-md-2 btn-tryout"
                          >
                            Ragu
                          </Button>
                        )}
                      {doubts.find((itm) => itm === idQuestion) && (
                        <Button
                          onClick={setSure}
                          type="button"
                          color="warning"
                          className="col-md-2 btn-tryout"
                        >
                          Yakin
                        </Button>
                      )}
                      {myAnswer &&
                        myAnswer.find(
                          (item) =>
                            `${item.question_number}` === idQuestion &&
                            item.question_answer_id != null
                        ) && (
                          <Button
                            onClick={clearQuestion}
                            type="button"
                            color="primary"
                            className="col-md-4 btn-tryout"
                          >
                            Hapus Jawaban
                          </Button>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" sm="12">
                <Container className="question-container">
                  {questions &&
                    questions.length > 0 &&
                    questions.map((item) => (
                      <Container className="group-card" id={item.items[0].number + 1}>
                        <h6 className="h6 font-weight-bold">
                          {`${item.key} [${item.duration} menit]`}
                        </h6>
                        <ListofQuestion>
                          {item &&
                            item.items &&
                            item.items.length > 0 &&
                            item.items.map((num) => (
                              <Link
                                to={`/test/${idTest}/${num.number + 1}#num-${item.items[0].number + 1}`}
                                key={num.number}
                              >
                                <QuestionItem
                                  className={classnames(
                                    'text-default position-relative',
                                    {
                                      'border-success':
                                        myAnswer &&
                                        myAnswer.find(
                                          (itm) =>
                                            itm.question_number === num.number + 1 &&
                                            `${num.number + 1}` !== idQuestion
                                        ),
                                    },
                                    {
                                      'border-warning': doubts.find(
                                        (itm) => itm === `${num.number + 1}`
                                      ),
                                    },
                                    {
                                      'bg-info text-white': `${num.number + 1}` === idQuestion,
                                    }
                                  )}
                                >
                                  {num.number + 1}
                                  <div
                                    style={{
                                      height: 20,
                                      width: 20,
                                      position: 'absolute',
                                      top: '-5px',
                                      right: '-5px',
                                      textAlign: 'center',
                                    }}
                                    className={classnames(
                                      'bg-secondary rounded-circle',
                                      {
                                        'bg-success':
                                          myAnswer &&
                                          myAnswer.find(
                                            (itm) =>
                                              itm.question_number === num.number + 1 &&
                                              `${num.number + 1}` !== idQuestion &&
                                              itm.answer_id !== -19
                                          ),
                                      },
                                      {
                                        'bg-primary':
                                          myAnswer &&
                                          myAnswer.find(
                                            (itm) =>
                                              itm.question_number === num.number + 1 &&
                                              `${num.number + 1}` !== idQuestion &&
                                              itm.answer_id === -19
                                          ),
                                      },
                                      {
                                        'bg-secondary': `${num.number + 1}` === idQuestion,
                                      },
                                      {
                                        'bg-warning': doubts.find(
                                          (itm) => itm === `${num.number + 1}`
                                        ),
                                      }
                                    )}
                                  >
                                    {myAnswer !== null && myAnswer[num.number]
                                      ? String.fromCharCode(64 + myAnswer[num.number].answer_id)
                                      : '-'}
                                  </div>
                                </QuestionItem>
                              </Link>
                            ))}
                        </ListofQuestion>
                      </Container>
                    ))}
                </Container>
                <br />
                <SubmitBtn
                  onClick={() => setShowSubmit(true)}
                  type="button"
                  className="btn text-white"
                  color="info"
                >
                  Kumpulkan Sekarang
                </SubmitBtn>
                <br />
                <br />
                <Container>
                  <h6 className="h6 font-weight-bold">Keterangan &quot;Status Jawaban&quot;</h6>
                  <ul className="legend">
                    <li>
                      <QuestionItem
                        className="text-default position-relative border-warning"
                        style={{
                          width: 49,
                          float: 'left',
                        }}
                      >
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            position: 'absolute',
                            top: '-5px',
                            right: '-5px',
                          }}
                          className="bg-warning rounded-circle"
                        />
                      </QuestionItem>
                      <label>Jawaban ragu-ragu</label>
                    </li>
                    <li>
                      <QuestionItem
                        className="text-default position-relative border-success"
                        style={{
                          width: 49,
                          float: 'left',
                        }}
                      >
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            position: 'absolute',
                            top: '-5px',
                            right: '-5px',
                          }}
                          className="bg-success rounded-circle"
                        />
                      </QuestionItem>
                      <label>Jawaban pasti</label>
                    </li>
                    <li>
                      <QuestionItem
                        className="text-default position-relative bg-info"
                        style={{
                          width: 49,
                          float: 'left',
                        }}
                      >
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            position: 'absolute',
                            top: '-5px',
                            right: '-5px',
                          }}
                          className="bg-secondary rounded-circle"
                        />
                      </QuestionItem>
                      <label>Soal masih dikerjakan</label>
                    </li>
                  </ul>
                </Container>
              </Col>
            </Row>
            <Modal isOpen={showHelp} toggle={toggle}>
              <ModalHeader toggle={toggle}>Petunjuk Pengerjaan</ModalHeader>
              <ModalBody>
                <div dangerouslySetInnerHTML={{ __html: isntruction }} />
              </ModalBody>
            </Modal>
            <ModalSubmit
              isOpen={showSubmit}
              toggle={() => setShowSubmit(false)}
              onSubmit={submitAllAnswers}
              doubts={doubts}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default TestView;

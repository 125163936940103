/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  // Alert,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import ContentCard from '../../components/cards/ContentCard';
import LoadingSpinner from '../../components/LoadingSpinner';
import DetailModal from '../../components/DetailModal';
import { URLS } from '../../constants';
// import { checkErrorMsg } from '../../utils/notifications';
import { sgAxios } from '../../utils/sgAxios';
import history from '../../helpers/history';

const TryoutNew = () => {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [size] = useState(4);
  const [pagination, setPagination] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const gotoCheckout = async (slug) => {
    history.push(`/checkout/${slug}`);
    window.location.reload();
  };

  // const { search } = useLocation();

  useEffect(() => {
    // const id = new URLSearchParams(search).get('emaj');
    const auth = JSON.parse(Cookies.get('_tryoutuser'));
    if (auth && auth.user && auth.user.data_ok === false) {
      history.push('/me');
      window.location.reload();
    }

    const fetchSeries = async () => {
      setIsLoading(true);
      try {
        const resp = await sgAxios.get(`${URLS.USER.GET_SERIES}/active`, {
          params: {
            size,
            page,
          },
        });
        const respData = get(resp, 'data.data');
        setPagination(get(resp, 'data.paginate'));
        setPageCount(Math.ceil(get(resp, 'data.paginate.total') / size));
        setSeries(respData);
        setIsLoading(false);
      } catch (error) {
        // checkErrorMsg(error);
        setIsLoading(false);
      }
    };
    fetchSeries();
  }, [page, size]);

  return (
    <>
      <Card className="shadow">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <CardTitle className="font-weight-bold mb-0">SIMAK UI</CardTitle>
        </CardHeader>
        <CardBody>
          {/* <Alert color="danger">
            <div className="font-weight-bold">Jadwal Try Out UTBK 2021 Part 3</div>
            <div>
              Pengerjaan : Jumat, 25 September 2020 pukul 13.01 WIB sampai dengan Jumat, 2 Oktober
              2020 pukul 23.59 WIB
            </div>
            <div>
              Pembahasan dan Analisis dapat diakses mulai Sabtu, 3 Oktober 2020 pukul 02.59 WIB
            </div>
          </Alert> */}
          <Row>
            {isLoading && <LoadingSpinner />}
            {series.map((item) => (
              <Col key={item.id} md="6" sm="12" className="pb-3">
                <ContentCard
                  status="sale"
                  name={item.label}
                  regEnd={item.effective_until}
                  price={item.price}
                  field={item.entrance_major.label}
                  handleClick={async () => {
                    await setSelectedItem(item);
                    // setShowModal(true);
                    gotoCheckout(item.slug);
                  }}
                />
              </Col>
            ))}
          </Row>
          {pagination && (
            <Pagination aria-label="Page navigation example">
              {page > 1 && (
                <PaginationItem disabled={page > 1} onClick={() => setPage(page - 1)}>
                  <PaginationLink previous href="#" />
                </PaginationItem>
              )}
              {page >= 3 &&
                [...Array(pageCount)].slice(page - 2, page).map((pages, i) => (
                  <PaginationItem active={i === page} key={i}>
                    <PaginationLink onClick={() => setPage(i + 1)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
              <PaginationItem active>
                <PaginationLink href="#">{page}</PaginationLink>
              </PaginationItem>
              {page + 2 < pageCount &&
                [...Array(pageCount)].slice(page, page + 2).map((pages, i) => (
                  <PaginationItem active={i === page} key={i}>
                    <PaginationLink
                      onClick={() => {
                        setPage(i + 1);
                        // fetchSeries();
                      }}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
              {page < pageCount && (
                <PaginationItem disabled={page >= pageCount} onClick={() => setPage(page + 1)}>
                  <PaginationLink next href="#" />
                </PaginationItem>
              )}
            </Pagination>
          )}
        </CardBody>
      </Card>
      <DetailModal details={selectedItem} show={showModal} toggle={() => setShowModal(false)} />
    </>
  );
};

export default TryoutNew;

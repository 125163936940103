import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import { checkErrorMsg } from '../../utils/notifications';
import { sgAxios } from '../../utils/sgAxios';
import { URLS } from '../../constants';

const ImageItem = styled.img`
  width: 100%;
  height: 451px;
  object-fit: cover;
`;

const BannerSlider = () => {
  const [items, setItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(true);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <ImageItem src={item.url} />
      </CarouselItem>
    );
  });

  useEffect(() => {
    const getSliders = async () => {
      try {
        const resp = await sgAxios.get(URLS.PUBLIC.SLIDER);
        if (resp && resp.data && resp.data.data) {
          setItems(resp.data.data);
        }
      } catch (e) {
        checkErrorMsg(e);
      }
    };
    getSliders();
  }, []);

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="rounded overflow-hidden"
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

export default BannerSlider;

import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Container, Table, CardFooter } from 'reactstrap';
import { get } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { checkAxiosErrResponse, sgAxios } from '../../utils/sgAxios';
import { URLS } from '../../constants';
import toRupiah from '../../helpers/toRupiah';
import LoadingSpinner from '../../components/LoadingSpinner';

const TryoutWaiting = () => {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [size] = useState(5);
  const [pagination, setPagination] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const getMe = async (lPage) => {
    const resp = await sgAxios.get(`${URLS.USER.GET_TRYOUT}/me`, {
      params: {
        size,
        page: lPage,
      },
    });
    const respData = get(resp, 'data.data');
    setPagination(get(resp, 'data.paginate'));
    setPageCount(Math.ceil(get(resp, 'data.paginate.total') / size));
    setSeries(respData);
    setIsLoading(false);
    return resp;
  };
  useEffect(() => {
    const fetchSeries = async () => {
      setIsLoading(true);
      try {
        await getMe(1);
      } catch (error) {
        checkAxiosErrResponse(error);
        setIsLoading(false);
      }
    };
    fetchSeries();
  }, [size]);
  const handlePageClick = (event) => {
    getMe(event.selected + 1);
  };
  return (
    <Card className="mb-4">
      <CardHeader className="d-flex justify-content-between align-items-center">
        <CardTitle className="font-weight-bold mb-0">Daftar Tryout Saya</CardTitle>
      </CardHeader>
      <CardBody>
        <Container>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div style={{ overflowX: 'scroll' }}>
              <Table striped className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-right">Status</th>
                    <th>Nama</th>
                    {/* <th>Tipe</th> */}
                    <th>Tanggal</th>
                    <th>Tanggal Beli</th>
                    <th className="text-right">Harga</th>
                  </tr>
                </thead>
                <tbody>
                  {series.map((item, i) => (
                    <tr key={item.id}>
                      <td className="text-center">{i + 1}</td>
                      <td className="td-actions text-center">
                        {/* 
                          - belum dikerjakan &
                          - status pending (PD) atau ready (RD) &
                          - order status failed (3) atau pending (1)
                          */}
                        {item.user_tryout_id === 0 &&
                          (item.ready === 'PD' || item.ready === 'RD') &&
                          (item.order_status_id === 3 || item.order_status_id === 1) && (
                            <Link to={`/checkout/${item.slug}`}>
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-info btn-icon btn-sm "
                                data-original-title=""
                                title=""
                              >
                                Bayar
                              </button>
                            </Link>
                          )}
                        {/* 
                          - sudah dikerjakan dikerjakan
                          */}
                        {item.user_tryout_id > 0 && (
                          <Link to={`/result/${item.user_tryout_id}`}>
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-info btn-icon btn-sm "
                              data-original-title=""
                              title=""
                            >
                              Detail
                            </button>
                          </Link>
                        )}
                        {/* 
                          - belum dikerjakan dikerjakan &
                          - status pending (PD) &
                          - order status success
                          */}
                        {item.user_tryout_id === 0 &&
                          item.ready === 'PD' &&
                          item.order_status_id === 2 && <p>Bersabar</p>}
                        {/* 
                          - belum dikerjakan dikerjakan &
                          - status Expired (EX)
                          */}
                        {item.user_tryout_id === 0 && item.ready === 'EX' && <p>Berakhir</p>}
                        {/* 
                          - belum dikerjakan dikerjakan &
                          - status Ready (RD) &
                          - order status success
                          */}
                        {item.user_tryout_id === 0 &&
                          item.ready === 'RD' &&
                          item.order_status_id === 2 && (
                            <Link to={`/test/${item.id}/1/fist`}>
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-danger btn-icon btn-sm "
                              >
                                Kerjakan
                              </button>
                            </Link>
                          )}
                      </td>
                      <td className="text-truncate">
                        {item.label}
                        {/* <br /> */}
                        {/* <span className="data-pk">#{item.id}</span> */}
                      </td>
                      {/* <td>{item.entrance_major.label}</td> */}
                      <td>{moment(item.effective_date).format('DD-MMM-YYYY')}</td>
                      <td>{moment(item.created_at).format('DD-MMM-YYYY HH:mm:ss')}</td>
                      <td className="text-right">{toRupiah(item.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Container>
      </CardBody>
      <CardFooter>
        {pagination && (
          <ReactPaginate
            className="pagination"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        )}
      </CardFooter>
    </Card>
  );
};

export default TryoutWaiting;

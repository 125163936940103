import React from 'react';
import RequestPasswordCard from '../components/Auth/RequestPasswordCard';

const RequestPassword = () => {
  return (
    <div>
      <RequestPasswordCard />
    </div>
  );
};

export default RequestPassword;

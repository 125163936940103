/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  // Alert,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
// import ContentCard from '../../components/cards/ContentCard';
import LoadingSpinner from '../../components/LoadingSpinner';
import { URLS } from '../../constants';
import { checkErrorMsg } from '../../utils/notifications';
import { sgAxios } from '../../utils/sgAxios';
import toRupiah from '../../helpers/toRupiah';
import dateDMY from '../../helpers/dateDMY';

const TryoutNew = () => {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();

  const fetchSeries = async (id) => {
    setIsLoading(true);
    try {
      const resp = await sgAxios.get(`${URLS.USER.GET_SERIES_DETAIL}/${id}`);
      const respData = get(resp, 'data.data');
      setSeries(respData);
      setIsLoading(false);
    } catch (error) {
      checkErrorMsg(error);
      setIsLoading(false);
    }
  };

  const generatePayment = async (code) => {
    try {
      const resp = await sgAxios.post(URLS.PAYMENT.SNAP, {
        code,
      });
      if (resp && resp.data && resp.data.data) {
        const respData = get(resp, 'data.data');
        setIsLoading(false);
        window.snap.pay(respData.token);
      }
    } catch (error) {
      setIsLoading(false);
      checkErrorMsg(error);
    }
  };

  const checkout = async () => {
    setIsLoading(true);
    try {
      const resp = await sgAxios.post(URLS.USER.ORDER_SLUG, {
        slug,
      });
      if (resp && resp.data && resp.data.data) {
        const respData = get(resp, 'data.data');
        generatePayment(respData.transaction_code);
      }
    } catch (error) {
      setIsLoading(false);
      checkErrorMsg(error);
    }
  };

  useEffect(() => {
    if (series.length === 0) {
      fetchSeries(slug);
    }
  }, [slug, series]);

  return (
    <Container>
      <Row>
        <Col md="8" sm="12">
          <Card className="shadow">
            {isLoading && <LoadingSpinner />}
            <CardHeader className="d-flex justify-content-between align-items-center">
              <CardTitle className="font-weight-bold mb-0">Checkout</CardTitle>
            </CardHeader>
            <CardBody>
              {/* <Alert color="danger">
                <div className="font-weight-bold">Jadwal Try</div>
                <div>
                  Pengerjaan : Jumat, 25 September 2020 pukul 13.01 WIB sampai dengan Jumat, 2
                  Oktober 2020 pukul 23.59 WIB
                </div>
                <div>
                  Pembahasan dan Analisis dapat diakses mulai Sabtu, 3 Oktober 2020 pukul 02.59 WIB
                </div>
              </Alert> */}
              <Row>
                <Col md="12" className="card-container">
                  <h4>{series.label}</h4>
                  <h6>
                    {series &&
                      series.entrance_major_description && `${series.entrance_major_description}`}
                  </h6>
                  <Row>
                    <Col md="4">
                      <span>Kelompok</span>
                    </Col>
                    <Col md="8" className="val">
                      <span>: </span>
                      <span>
                        {series &&
                          series.study_program_group &&
                          `${series.study_program_group.name}`}
                      </span>
                    </Col>
                    <Col md="4">
                      <span>Waktu Pengerjaan</span>
                    </Col>
                    <Col md="8" className="val">
                      <span>: </span>
                      <span>
                        {`${dateDMY(series.effective_date)} sd.${dateDMY(series.effective_until)}`}
                      </span>
                    </Col>
                    <Col md="4">
                      <span>Durasi Pengerjaan</span>
                    </Col>
                    <Col md="8" className="val">
                      <span>: </span>
                      <span>{`${series.duration} Menit`}</span>
                    </Col>
                    <Col md="4">
                      <span>Jumlah Mata Pelajaran</span>
                    </Col>
                    <Col md="8" className="val">
                      <span>: </span>
                      <span>{`${series.subject_count}`}</span>
                    </Col>
                    <Col md="4">
                      <span>Jumlah Soal</span>
                    </Col>
                    <Col md="8" className="val">
                      <span>: </span>
                      <span>{`${series.question_count}`}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" sm="12">
          <Card className="shadow">
            {isLoading && <LoadingSpinner />}
            <CardHeader>
              <CardTitle className="font-weight-bold mb-0">
                <div className="font-weight-bolder">Informasi Pembayaran</div>
              </CardTitle>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <Row>
                <Col md="12 mb-5">
                  <table className="table-label-value">
                    <tbody>
                      <tr>
                        <td>Harga</td>
                        <td>:</td>
                        <td className="val">{toRupiah(series.price)}</td>
                      </tr>
                      <tr>
                        <td>Jumlah</td>
                        <td>:</td>
                        <td className="val">1</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Button
                size="md"
                color="warning"
                type="button"
                onClick={() => {
                  checkout();
                }}
              >
                Lanjutkan Pembayaran
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TryoutNew;

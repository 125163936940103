import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const Wrapper = styled.footer`
  background: #2085bd;
`;

const UnorderList = styled.ul`
  list-style: none;
`;

const Footer = () => (
  <Wrapper className="p-4 mt-2">
    <Container>
      <Row>
        <Col sm="12" md="4">
          <UnorderList>
            <h6 className="text-white font-weight-bold">Kantor</h6>
            <p className="text-white">
              Jl. KomjenPol M Jasin (Akses UI) No.62 Kel. Tugu Kec. Cimanggis, Kota Depok Jawa Barat
              16451
            </p>
          </UnorderList>
        </Col>
        <Col sm="12" md="4">
          <UnorderList>
            <h6 className="text-white font-weight-bold">Cabang</h6>
            <li>
              <a href="https://maps.app.goo.gl/NuEUkLFb3mcz7bhHA" target="blank" className="text-white d-flex align-items-end">
                Cabang AKSES UI
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+Cabang+Nusantara+2/@-6.3961457,106.8054288,15z/data=!4m8!1m2!2m1!1ssg+nusantara!3m4!1s0x2e69e9571888a2b5:0xc133713c182283ca!8m2!3d-6.3962293!4d106.8142474?hl=en" target="blank" className="text-white d-flex align-items-end">
                Cabang NUSANTARA
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+Cabang+Depok+II/@-6.4044977,106.8388195,16z/data=!4m8!1m2!2m1!1sSG++depok+2!3m4!1s0x2e69eba4fc3e5d29:0xa7a38946bde3f6a1!8m2!3d-6.4036103!4d106.8359903" target="blank" className="text-white d-flex align-items-end">
                Cabang DEPOK II
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbingan+Belajar+SG+Depok+Timur/@-6.4053237,106.8506744,17z/data=!4m8!1m2!2m1!1sgiant+ekstra+jalan+tole+iskandar+depok!3m4!1s0x0:0x81bf48eff959fb8d!8m2!3d-6.4055624!4d106.850842" target="blank" className="text-white d-flex align-items-end">
                Cabang DEPOK TIMUR
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+Kalisari/@-6.3303789,106.850437,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69edb023cdb84b:0xe45a8cc5938c4ef8!8m2!3d-6.3303842!4d106.8526257" target="blank" className="text-white d-flex align-items-end">
                Cabang KALISARI
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+cabang+Alternatif/@-6.3749577,106.9128681,17z/data=!3m1!4b1!4m5!3m4!1s0x2e6993545284f50d:0xd3716a2641efb91!8m2!3d-6.374963!4d106.9150568" target="blank" className="text-white d-flex align-items-end">
                Cabang ALTERNATIF
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/SG+Cabang+Cililitan/@-6.3079775,106.8238352,13z/data=!4m8!1m2!2m1!1ssg+cililitan!3m4!1s0x2e69f2f895d7e4df:0x484a05f5095ab02e!8m2!3d-6.263306!4d106.866148" target="blank" className="text-white d-flex align-items-end">
                Cabang CILILITAN
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Salemba+Group+Cibubur/@-6.3508349,106.8816763,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69ece8da2c9933:0xb4cb86f8550a7cc5!8m2!3d-6.3508402!4d106.883865" target="blank" className="text-white d-flex align-items-end">
                Cabang ARUNDINA
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/SG+Cabang+Cipayung/@-6.3328813,106.8903074,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69ed255a06726f:0x21ec4a081a8aca51!8m2!3d-6.3328866!4d106.8924961" target="blank" className="text-white d-flex align-items-end">
                Cabang CIPAYUNG
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+Pasar+Minggu/@-6.2855636,106.8355183,19z/data=!4m12!1m6!3m5!1s0x2e69f2117df8da4d:0xdb6f2655fa53f7c7!2sRuko+Royal+Spring+Bussines+Park!8m2!3d-6.2856874!4d106.835549!3m4!1s0x0:0x71c1ad22ba1c9016!8m2!3d-6.2857725!4d106.835368" target="blank" className="text-white d-flex align-items-end">
                Cabang PASAR MINGGU
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/BIMBEL+SG+RAWAMANGUN/@-6.195595,106.883747,15z/data=!4m5!3m4!1s0x0:0xfa02a4d9de619329!8m2!3d-6.195595!4d106.883747" target="blank" className="text-white d-flex align-items-end">
                Cabang AL-AZHAR RAWAMANGUN
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+cabang+Karadenan/@-6.5075645,106.8047127,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69c22ec7540127:0xb8ed9afeec7d6a4d!8m2!3d-6.5075698!4d106.8069014" target="blank" className="text-white d-flex align-items-end">
                Cabang KARADENAN
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimel+Sg+Merdeka/@-6.5809983,106.8080052,21z/data=!4m12!1m6!3m5!1s0x0:0x1d2bdc41d25e8094!2sBimel+Sg+Merdeka!8m2!3d-6.5808118!4d106.8080266!3m4!1s0x0:0x1d2bdc41d25e8094!8m2!3d-6.5808118!4d106.8080266" target="blank" className="text-white d-flex align-items-end">
                Cabang PAJAJARAN
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+cabang+Pamulang/@-6.3434315,106.753211,13z/data=!4m8!1m2!2m1!1ssg+pamulang!3m4!1s0x2e69ef8194a3729f:0x2e3f48e024fa3c6f!8m2!3d-6.3427582!4d106.7290129" target="blank" className="text-white d-flex align-items-end">
                Cabang PAMULANG
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Bimbel+SG+-+Lubang+Buaya/@-6.2943893,106.9022384,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69f3edc63f36e3:0x819ab846274034bb!8m2!3d-6.2943946!4d106.9044271" target="blank" className="text-white d-flex align-items-end">
                Cabang LUBANG BUAYA
              </a>
            </li>
          </UnorderList>
        </Col>
        <Col sm="12" md="4">
          <UnorderList>
            <h6 className="text-white font-weight-bold">Sosial Media</h6>
            <li>
              <a href="https://www.instagram.com/bimbelsg" target="blank" className="text-white d-flex align-items-end">
                <i className="ri-instagram-line mr-2" />
                <span>Bimbel SG</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/bimbelsg" target="blank" className="text-white d-flex align-items-end">
                <i className="ri-facebook-box-line mr-2" />
                <span>Bimbel SG</span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCStA2TDfRCgg-Wpax_gkjXA" target="blank" className="text-white d-flex align-items-end">
                <i className="ri-youtube-line mr-2" />
                <span>Bimbel SG</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/bimbelsg" target="blank" className="text-white d-flex align-items-end">
                <i className="ri-twitter-line mr-2" />
                <span>@bimbelsg</span>
              </a>
            </li>
            <li>
              <a href="mailto:sgpusat@gmail.com" target="blank" className="text-white d-flex align-items-end">
                <i className="ri-mail-line mr-2" />
                <span>sgpusat@gmail.com</span>
              </a>
            </li>
            <li>
              <a href="https://wa.me/62" target="blank" className="text-white d-flex align-items-end">
                <i className="ri-whatsapp-line mr-2" />
                <span>WhatsApp</span>
              </a>
            </li>
            <li>
              <a href="/" target="blank" className="text-white d-flex align-items-end">
                <i className="ri-phone-line mr-2" />
                <span>021 87707205</span>
              </a>
            </li>
          </UnorderList>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Footer;


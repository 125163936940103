import React, { useState, useEffect } from 'react';
import { Card, Container, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { sgAxios } from '../../utils/sgAxios';
import { URLS } from '../../constants';
import LoadingSpinner from '../../components/LoadingSpinner';

const TryoutResult = () => {
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(4);
  const [pagination, setPagination] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const fecthResults = async () => {
      setIsLoading(true);
      try {
        const resp = await sgAxios.get(`${URLS.USER.GET_TRYOUT}/summary`, {
          params: {
            page,
            size,
          },
        });
        const respData = get(resp, 'data.data');
        setPagination(get(resp, 'data.paginate'));
        setPageCount(get(resp, 'data.paginate.page_count'));
        setResults(respData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fecthResults();
  }, [page, size]);

  return (
    <Card className="mb-4">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container>
          <div style={{ overflowX: 'scroll' }}>
            <Table striped className="table">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Nama</th>
                  {/* <th>Tipe</th> */}
                  <th>Tanggal</th>
                  <th className="text-right">Skor</th>
                  <th className="text-right">Status</th>
                </tr>
              </thead>
              {results && (
                <tbody>
                  {results.map((item, idx) => (
                    <tr key={item.id}>
                      <td className="text-center">{idx + 1}</td>
                      <td>{item.label}</td>
                      {/* <td>{item.entrance_major_name}</td> */}
                      <td>{moment(item.created_at).format('DD-MM-YYYY')}</td>
                      <td className="text-right">{item.score}</td>
                      <td className="td-actions text-right">
                        <Link to={`/result/${item.id}`}>
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-info btn-icon btn-sm "
                            data-original-title=""
                            title=""
                          >
                            Detail
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
          {pagination && (
            <Pagination aria-label="Page navigation example">
              {page > 1 && (
                <PaginationItem disabled={page > 1} onClick={() => setPage(page - 1)}>
                  <PaginationLink previous href="#" />
                </PaginationItem>
              )}
              {page >= 3 &&
                [...Array(pageCount)].slice(page - 2, page).map((pages, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <PaginationItem active={i === page} key={i}>
                    <PaginationLink onClick={() => setPage(i + 1)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
              <PaginationItem active>
                <PaginationLink href="#">{page}</PaginationLink>
              </PaginationItem>
              {page < pageCount && (
                <PaginationItem disabled={page >= pageCount} onClick={() => setPage(page + 1)}>
                  <PaginationLink next href="#" />
                </PaginationItem>
              )}
            </Pagination>
          )}
        </Container>
      )}
    </Card>
  );
};

export default TryoutResult;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  Form,
  Input,
  Button,
  CardFooter,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../LoadingSpinner';
import { checkErrorMsg, showSuccessMsg } from '../../../utils/notifications';
import { sgAxios } from '../../../utils/sgAxios';
import { URLS } from '../../../constants';
import history from '../../../helpers/history';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }

  return '';
};

const MyCard = styled(Card)`
  max-width: 540px;
  margin: 0 auto;
`;

const ResetPasswordCard = () => {
  const form = useRef();

  const [pass, setPassword] = useState('');
  const [pConfirm, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const { params } = useRouteMatch();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const resp = await sgAxios.put(URLS.PUBLIC.SET_PASS, {
        reset_token: params.token,
        password: pass,
        password_confirmation: pConfirm,
      });
      setLoading(false);
      if (resp) {
        history.push('/login');
        window.location.reload();
        showSuccessMsg('Password berhasil diubah').goAway(10000);
      }
    } catch (error) {
      checkErrorMsg(error);
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <MyCard>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <CardBody className="px-4 pb-2">
            <CardTitle className="text-center mt-3 mb-5">
              <h4 className="font-weight-bolder mb-0">Silahkan masukkan kata sandi baru</h4>
            </CardTitle>
            <Form onSubmit={handleSubmit} ref={form}>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ri-lock-fill text-primary" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="password"
                  id="loginEmail"
                  placeholder="Kata Sandi Baru"
                  value={pass}
                  onChange={(e) => setPassword(e.target.value)}
                  validations={[required]}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ri-lock-fill text-primary" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="password"
                  id="loginEmail"
                  placeholder="Konfirmasi Kata Sandi Baru"
                  value={pConfirm}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  validations={[required]}
                />
              </InputGroup>
              <Button
                type="submit"
                color="primary"
                className="w-100 my-4 py-2"
                disabled={pass === '' || pConfirm === '' || pass !== pConfirm}
              >
                {loading && <span className="spinner-border spinner-border-sm" />}
                <span>Kirim</span>
              </Button>
            </Form>
          </CardBody>
          <CardFooter className="d-flex justify-content-around px-4">
            <Link to="/login">Ke Halaman Login</Link>
          </CardFooter>
        </>
      )}
    </MyCard>
  );
};

export default ResetPasswordCard;

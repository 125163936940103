/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Container, Card, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const TryoutSubmitted = () => {
  return (
    <Container>
      <Card className="shadow-sm">
        <CardBody className="d-flex flex-column">
          <h4 className="font-weight-bold text-center">🥳🥳Ujian kamu berhasil dikumpulkan🥳🥳</h4>
          <img className="w-75" src="/images/success-payment.svg" alt="Illustration" />
          <div className="text-center py-4">
            <Link to="/dashboard/test-result">
              <Button className="py-2 px-3" size="sm" type="button" color="primary">
                ke dashboard
              </Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default TryoutSubmitted;

/**
 * URL CONFIGS
 */
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const URLS = {
  PUBLIC: {
    UPLOAD_FILE: `${API_BASE_URL}/api/v1/file/upload`,
    GET_UNIVERSITIES: `${API_BASE_URL}/api/v1/university`,
    GET_FACULTIES: `${API_BASE_URL}/api/v1/faculty`,
    GET_PROVINCES: `${API_BASE_URL}/api/v1/province`,
    GET_CITIES: `${API_BASE_URL}/api/v1/regency`,
    GET_GRADES: `${API_BASE_URL}/api/v1/class`,
    GET_MAJORS: `${API_BASE_URL}/api/v1/major`,
    GET_SCHOOL: `${API_BASE_URL}/api/v1/school`,
    GET_STUDY_PROGRAMS: `${API_BASE_URL}/api/v1/study-program-mapping`,
    GET_ENTRANCE_OPTIONS: `${API_BASE_URL}/api/v1/entrance-major`,
    GET_PROGRAM_GROUP: `${API_BASE_URL}/api/v1/study-program-group`,
    GET_SEX: `${API_BASE_URL}/api/v1/sex`,
    RESET_PASS: `${API_BASE_URL}/api/v1/auth/reset-password`,
    SET_PASS: `${API_BASE_URL}/api/v1/auth/reset-password`,
    SLIDER: `${API_BASE_URL}/api/v1/slider`,
  },
  USER: {
    UPLOAD_FILE: `${API_BASE_URL}/api/v1/file/upload`,
    SET_PROFILE: `${API_BASE_URL}/api/v1/user/set-profile`,
    GET_ME: `${API_BASE_URL}/api/v1/auth/me`,
    PUT_REFRESH: `${API_BASE_URL}/api/v1/auth/refresh`,
    GET_SERIES: `${API_BASE_URL}/api/v1/series`,
    GET_SERIES_DETAIL: `${API_BASE_URL}/api/v1/series/slug`,
    GET_TRYOUT: `${API_BASE_URL}/api/v1/tryout`,
    USER_ANSWER: `${API_BASE_URL}/api/v1/user-answer`,
    ORDER: `${API_BASE_URL}/api/v1/order`,
    ORDER_SLUG: `${API_BASE_URL}/api/v1/order/slug`,
    RESULT: `${API_BASE_URL}/api/v1/tryout/result`,
    RESULT2: `${API_BASE_URL}/api/v1/tryout/result/2`,
  },
  PAYMENT: {
    GOPAY: `${API_BASE_URL}/api/v1/payment/gopay`,
    SNAP: `${API_BASE_URL}/api/v1/payment/snap`,
  },
};

import { format } from 'date-fns';

const dateDMY = (date) => {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  return format(d, 'dd MMM yyyy');
};

export default dateDMY;

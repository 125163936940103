import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import ContentCard from '../../components/cards/ContentCard';
import { sgAxios } from '../../utils/sgAxios';
import { showErrorMsg } from '../../utils/notifications';
import LoadingSpinner from '../../components/LoadingSpinner';
import { URLS } from '../../constants';
import history from '../../helpers/history';

const TryoutWaiting = () => {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(4);
  const [pagination, setPagination] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const fetchSeries = async () => {
      setIsLoading(true);
      try {
        const resp = await sgAxios.get(`${URLS.USER.GET_TRYOUT}/pending`, {
          params: {
            size,
            page,
          },
        });
        const respData = get(resp, 'data.data');
        setPagination(get(resp, 'data.paginate'));
        setPageCount(Math.ceil(get(resp, 'data.paginate.total') / size));
        setSeries(respData);
        setIsLoading(false);
      } catch (error) {
        showErrorMsg('😢 Gagal mendapatkan data series');
        setIsLoading(false);
      }
    };
    fetchSeries();
  }, [page, size]);

  return (
    <Card className="shadow">
      <CardHeader className="d-flex justify-content-between align-items-center">
        <CardTitle className="font-weight-bold mb-0">Menunggu Pembayaran</CardTitle>
        <div>
          <Link to="/" className="d-flex align-items-end">
            <i className="ri-file-list-3-line mr-1" />
            <div style={{ fontSize: '13px' }}>SIMAK UI</div>
          </Link>
        </div>
      </CardHeader>
      <CardBody>
        {/* <Alert color="danger">
          <div className="font-weight-bold">Jadwal Try Out UTBK 2021 Part 3</div>
          <div>
            Pengerjaan : Jumat, 25 September 2020 pukul 13.01 WIB sampai dengan Jumat, 2 Oktober
            2020 pukul 23.59 WIB
          </div>
          <div>
            Pembahasan dan Analisis dapat diakses mulai Sabtu, 3 Oktober 2020 pukul 02.59 WIB
          </div>
        </Alert> */}
        <Row>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {series.map((item) => (
                <Col key={item.id} md="6" sm="12" className="pb-3">
                  <ContentCard
                    status="pending"
                    name={item.label}
                    regEnd={item.effective_until}
                    price={item.price}
                    handleClick={async () => {
                      history.push(`/checkout/${item.slug}`);
                      window.location.reload();
                    }}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>
        {pagination && (
          <Pagination aria-label="Page navigation example">
            {page > 1 && (
              <PaginationItem disabled={page > 1} onClick={() => setPage(page - 1)}>
                <PaginationLink previous href="#" />
              </PaginationItem>
            )}
            {page >= 3 &&
              [...Array(pageCount)].slice(page - 2, page).map((pages, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <PaginationItem active={i === page} key={i}>
                  <PaginationLink onClick={() => setPage(i + 1)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            <PaginationItem active>
              <PaginationLink href="#">{page}</PaginationLink>
            </PaginationItem>
            {page + 2 < pageCount &&
              [...Array(pageCount)].slice(page, page + 2).map((pages, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <PaginationItem active={i === page} key={i}>
                  <PaginationLink
                    onClick={() => {
                      setPage(i + 1);
                      // fetchSeries();
                    }}
                    href="#"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            {page < pageCount && (
              <PaginationItem disabled={page >= pageCount} onClick={() => setPage(page + 1)}>
                <PaginationLink next href="#" />
              </PaginationItem>
            )}
          </Pagination>
        )}
      </CardBody>
    </Card>
  );
};

export default TryoutWaiting;

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardBody, Button } from 'reactstrap';

const NotFound = () => {
  return (
    <Container>
      <Card>
        <CardBody className="d-flex flex-column align-items-center">
          <h4 className="h4 font-weight-bold">Oops.. Halaman tidak ditemukan</h4>
          <img className="w-75" src="/images/not-found.svg" alt="" />
          <Link to="/">
            <Button className="py-2 px-4" size="sm" color="primary" type="button">
              ke beranda
            </Button>
          </Link>
        </CardBody>
      </Card>
    </Container>
  );
};

export default NotFound;

import { toast } from 'react-toastify';

const SHOW_TIME = 2000;

export const showSuccessMsg = (msg, position, timeout) => {
  toast.success(msg, {
    position: position || toast.POSITION.TOP_CENTER,
    autoClose: timeout || SHOW_TIME,
  });
  toast.clearWaitingQueue();
};

export const showErrorMsg = (msg, position, timeout) => {
  toast.error(msg, {
    position: position || toast.POSITION.TOP_CENTER,
    autoClose: timeout || SHOW_TIME,
  });
  toast.clearWaitingQueue();
};

export const showWarningMsg = (msg, position, timeout) => {
  toast.warn(msg, {
    position: position || toast.POSITION.TOP_CENTER,
    autoClose: timeout || SHOW_TIME,
  });
  toast.clearWaitingQueue();
};

export const showInfoMsg = (msg, position, timeout) => {
  toast.info(msg, {
    position: position || toast.POSITION.TOP_CENTER,
    autoClose: timeout || SHOW_TIME,
  });
  toast.clearWaitingQueue();
};

export const checkErrorMsg = (err) => {
  if (!err.response) {
    return;
  }
  const status = err.response && err.response.status;
  if (status === 401) {
    showErrorMsg(`😢 Unauthorized`);
    window.location.href = '/login';
    return;
  }
  let errMsg = err.response && err.response.data && err.response.data.user_message;
  if (errMsg && errMsg !== '') {
    showErrorMsg(`${errMsg}`);
    return;
  }
  errMsg = err.response && err.response.data && err.response.data.message;
  if (errMsg && errMsg !== '') {
    showErrorMsg(`😢 ${errMsg}`);
    return;
  }
  const eres = err.response && err.response.data && err.response.data.errors;
  const errors = Object.values(eres);
  for (let i = 0; i < errors.length; i += 1) {
    for (let j = 0; j < errors.length; j += 1) {
      showErrorMsg(`😢 ${errors[i][j]}`);
    }
  }
};

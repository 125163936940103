import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import UserCard from '../components/Auth/UserCard';

const DashboardLayout = ({ currentUser, children }) => {
  return (
    <Container>
      <Row>
        <Col md="4" sm="12">
          <UserCard user={currentUser.user} />
          <Card className="mt-4 shadow">
            <CardBody className="d-flex flex-column">
              <Link to="/dashboard" className="text-primary py-2 d-flex align-items-center">
                <i className="ri-gift-fill mr-2" />
                <span>Baru</span>
              </Link>
              <Link to="/dashboard/waiting" className="text-primary py-2 d-flex align-items-center">
                <i className="ri-time-fill mr-2" />
                <span>Menunggu pembayaran</span>
              </Link>
              <Link to="/dashboard/my-test" className="text-primary py-2 d-flex align-items-center">
                <i className="ri-folder-3-fill mr-2" />
                <span>Tryout saya</span>
              </Link>
              <Link
                to="/dashboard/test-result"
                className="text-primary py-2 d-flex align-items-center"
              >
                <i className="ri-file-chart-fill mr-2" />
                <span>Hasil</span>
              </Link>
            </CardBody>
          </Card>
        </Col>
        <Col md="8" sm="12">
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardLayout;

import React, { Component } from 'react';
import moment from 'moment';

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: undefined,
    };
  }

  componentDidMount() {
    this.timeInterval = setInterval(() => {
      const { timeTillDate, timeFormat } = this.props;
      const then = moment(timeTillDate, timeFormat);
      const now = moment();
      if (now > then) return null;
      const countdown = moment
        .utc(moment(then, 'DD/MM/YYYY HH:mm:ss').diff(moment(now, 'DD/MM/YYYY HH:mm:ss')))
        .format('HH:mm:ss');
      const date = countdown;
      this.setState({ date });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  render() {
    const { date } = this.state;
    return <span>{date || '00:00:00'}</span>;
  }
}

export default Countdown;

import React from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';

const SubmitAnswer = ({ isOpen, toggle, onSubmit, doubts }) => {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kumpulkan Jawaban Anda?</ModalHeader>
        <ModalBody>
          {doubts && doubts.length > 0 && (
            <div className="text-danger text-bold" style={{ marginBottom: '10px' }}>
              Masih ada jawaban ragu-ragu di lembar kerja!
            </div>
          )}
          Jawaban akan dikunci dan tidak bisa diubah lagi. Apakah Anda sudah yakin?
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="secondary" onClick={toggle}>
            Batal
          </Button>
          <Button size="sm" color="danger" onClick={onSubmit}>
            Kumpulkan
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SubmitAnswer;

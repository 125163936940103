import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  Form,
  Input,
  Button,
  CardFooter,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../helpers/history';
import { login } from '../../../actions/auth';
import LoadingSpinner from '../../LoadingSpinner';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }

  return '';
};

const MyCard = styled(Card)`
  max-width: 540px;
  margin: 0 auto;
`;

const LoginCard = () => {
  const form = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    // form.current.validateAll();

    dispatch(login(email, password))
      .then((resp) => {
        if (resp && resp.user && resp.user.data_ok === false) {
          history.push('/me');
        } else {
          history.push('/dashboard');
        }
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <MyCard>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <CardBody className="px-4 pb-2">
            <CardTitle className="text-center mt-3 mb-5">
              <h4 className="font-weight-bolder mb-0">Masuk ke Sistem</h4>
              <small>Temukan jurusan yang tepat untuk kamu </small>
            </CardTitle>
            <Form onSubmit={handleLogin} ref={form}>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ri-mail-fill text-primary" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  id="loginEmail"
                  placeholder="Alamat Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  validations={[required]}
                />
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ri-lock-fill text-primary" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="password"
                  id="loginPass"
                  placeholder="Kata Sandi"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  validations={[required]}
                />
              </InputGroup>
              <Button type="submit" color="primary" className="w-100 my-4 py-2" disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm" />}
                <span>Masuk</span>
              </Button>
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              {/* <CheckButton style={{ display: 'none' }} ref={checkBtn} /> */}
            </Form>
          </CardBody>
          <CardFooter className="d-flex justify-content-around px-4">
            <Link to="/forget">Lupa kata sandi?</Link>
            <Link to="/register">Buat akun baru</Link>
          </CardFooter>
        </>
      )}
    </MyCard>
  );
};

export default LoginCard;

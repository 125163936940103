import React from 'react';
import LoginCard from '../../components/Auth/LoginCard';

const LoginPage = () => {
  return (
    <div>
      <LoginCard />
    </div>
  );
};

export default LoginPage;
